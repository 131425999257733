<template>
  <div>
    <loader-dialog :is-dialog-visible="isDialogVisible" />
    <v-card>
      <div class="d-flex align-center justify-space-between">
        <div>
          <v-card-title class="text-no-wrap">
            Membre
          </v-card-title>
        </div>

        <v-avatar
          color="primary"
          size="48"
          class="v-avatar-light-bg primary--text me-5"
        >
          <v-icon
            size="30"
            color="primary"
          >
            {{ icons.mdiCarArrowLeft }}
          </v-icon>
        </v-avatar>
      </div>

      <v-card-text>
        <v-list>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiAccount }}
              </v-icon>
              Nom:
            </span>
            <span
              class="text--secondary font-weight-bold"
              @click="openItem(elt.user)"
            >
              <v-chip
                pill
                link
              >
                <v-avatar
                  :color=" elt.user.profile_picture_path ? '' : 'primary'"
                  :class=" elt.user.profile_picture_path ? '' : 'v-avatar-light-bg primary--text'"
                  size="32"
                  left
                >
                  <v-img
                    v-if=" elt.user.profile_picture_path"
                    :src="`${ elt.user.profile_picture_path}`"
                  ></v-img>
                  <span v-else>{{ elt.user.name !== null && elt.user.name !== undefined ? elt.user.name.slice(0,2).toUpperCase() : null }}</span>
                </v-avatar>
                {{ elt.user.civility }} {{ elt.user.name }}
              </v-chip>
            </span>
          </v-list-item>
          <v-list-item
            v-show="elt.car !== null"
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiCarEstate }}
              </v-icon>
              Marque de la voiture:
            </span>
            <v-menu
              v-model="menu"
              location="top start"
              origin="top start"
              transition="slide-x-transition"
            >
              <template v-slot:activator="{ props }">
                <v-chip
                  pill
                  link
                  v-bind="props"
                  @click.prevent="menu = true"
                >
                  <v-avatar
                    :color=" elt.car && elt.car.photo_path ? '' : 'primary'"
                    :class=" elt.car && elt.car.photo_path ? '' : 'v-avatar-light-bg primary--text'"
                    size="32"
                    left
                  >
                    <v-img
                      v-if=" elt.car && elt.car.photo_path"
                      :src="`${ elt.car.photo_path }`"
                    ></v-img>
                    <span v-else>{{ elt.car !== null && elt.car.marque !== undefined ? elt.car.marque.slice(0,2).toUpperCase() : null }}</span>
                  </v-avatar>
                  {{ elt.car && elt.car.marque ? elt.car.marque : null }}
                </v-chip>
              </template>
              <v-card max-width="300" max-height="300">
                <v-avatar
                  :color=" elt.car && elt.car.photo_path ? '' : 'primary'"
                  :class=" elt.car && elt.car.photo_path ? '' : 'v-avatar-light-bg primary--text'"
                  size="300">
                  <v-img v-if="elt.car && elt.car.photo_path"
                         :src="`${ elt.car.photo_path }`"
                  ></v-img>
                  <span v-else>{{ elt.car !== null && elt.car.marque !== undefined ? elt.car.marque.slice(0,2).toUpperCase() : null }}</span>
                </v-avatar>
              </v-card>
            </v-menu>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon size="15">{{ icons.mdiSmokingOff }}</v-icon>
              Non fumeur:
            </span>
            <span class="text--secondary text-capitalize font-weight-bold">
              <v-chip
                small
                :color="elt.user.no_smoking ? 'primary' : 'error'"
                :class="`${ elt.user.no_smoking ? 'primary' : 'error'}--text`"
                class="v-chip-light-bg font-weight-bold"
              >
                {{ elt.user.no_smoking === true ? 'OUI' : 'NON' }}
              </v-chip>
            </span>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiPhone }}
              </v-icon>
              Tel:
            </span>
            <span class="text--secondary font-weight-bold">{{ elt.user.phone_number }}</span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiEmail }}
              </v-icon>
              Email:
            </span>
            <span class="text--secondary font-weight-bold">{{ elt.user.email }}</span>
          </v-list-item>
          <v-list-item
            v-show="elt.car !== null"
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiCarSettings }}
              </v-icon>
              Modèle de la voiture:
            </span>
            <span class="text--secondary font-weight-bold">{{ elt.car ? elt.car.model : null }}</span>
          </v-list-item>
          <v-list-item
            v-show="elt.car !== null"
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiCarDoor }}
              </v-icon>
              Année de sortie de la voiture:
            </span>
            <span class="text--secondary font-weight-bold">{{ elt.car ? elt.car.release_year : null }}</span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiFileDocument }}
              </v-icon>
              Permis validé le:
            </span>
            <span class="text--secondary font-weight-bold">{{ elt.user.license_validated_at ? formattingDate(elt.user.license_validated_at) : null }}</span>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <car-details-dialog
      :dialog="dialog"
      @clickout="closeDialog"
    />
  </div>
</template>

<script>
import {
  mdiAccount, mdiCarArrowLeft, mdiPhone, mdiEmail, mdiCarEstate,
  mdiCarSettings, mdiCarDoor, mdiFileDocument, mdiSmokingOff,
  mdiClose
} from '@mdi/js'
import CarDetailsDialog from '@core/components/user/CarDetailsDialog'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import { mapState } from 'vuex'
import { computed, ref, watch } from '@vue/composition-api'
import { formatDate } from '@core/date/dateFunctions'
import router from '@/router'
import store from '@/store'

export default {
  name: 'SearchDriver',
  components: { LoaderDialog, CarDetailsDialog },

  props: {
    search: {
      type: Object,
      default: null,
    },
  },

  setup(props, { emit }) {
    const menu = ref(false)
    const isDialogVisible = ref(false)
    const dialog = ref(false)
    const elt = computed({
      get: () => props.search,
      set: value => emit('update:object', value),
    })

    const formattingDate = date => formatDate(date)

    const openItem = item => {
      router.push({ name: 'showuser', params: { id: item.id } })
    }

    const openCar = () => {
      isDialogVisible.value = true
      store.dispatch('car/getCarById', this.search.car).then(() => {
        isDialogVisible.value = false
      })
      dialog.value = true
    }

    const closeDialog = () => {
      dialog.value = false
    }

    return {
      elt,
      dialog,
      isDialogVisible,
      menu,
      closeDialog,
      openCar,
      openItem,
      formattingDate,
      icons: {
        mdiCarArrowLeft,
        mdiAccount,
        mdiPhone,
        mdiEmail,
        mdiCarEstate,
        mdiCarDoor,
        mdiCarSettings,
        mdiFileDocument,
        mdiSmokingOff,
        mdiClose,
      },
    }
  },
}
</script>

<style scoped>

</style>
