<template>
  <div>
    <search-card
      :search="search"
      class="mb-4"
    />
    <search-driver :search="search" />
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import SearchCard from '@/components/search/SearchCard'
// eslint-disable-next-line import/extensions
import SearchDriver from '@/components/search/SearchDriver'

export default {
  name: 'SearchPanel',
  components: { SearchDriver, SearchCard },
  props: {
    search: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
