<template>
  <div>
    <custom-toolbar
      :title="'Détails d reserche'"
      @refresh="refreshObjet"
    />
    <v-row class="mt-1">
      <v-col
        cols="12"
        lg="4"
        md="4"
      >
        <v-skeleton-loader
          v-show="isDialogVisible"
          boilerplate
          class="mx-auto"
          elevation="2"
          max-width="360"
          type="card-avatar, article, actions, card-avatar, article, actions"
        ></v-skeleton-loader>
        <search-panel
          v-show="!isDialogVisible"
          :search="search"
        />
      </v-col>
      <v-col
        cols="12"
        lg="8"
        md="8"
      >

        <v-row v-if="search && search.id != null">
          <v-col
            cols="12"
            lg="12"
            md="12"
          >
            <Road :current-search="search" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getCurrentInstance, onMounted, ref } from '@vue/composition-api'
import {
  mdiAccount,
  mdiCar,
  mdiCarArrowLeft, mdiCarDoor,
  mdiCarEstate, mdiCarSettings,
  mdiCashMarker,
  mdiCashMultiple,
  mdiEmail, mdiFileDocument,
  mdiMapMarkerDistance,
  mdiPhone,
  mdiRefresh,
  mdiArrowLeftBoldCircleOutline,
} from '@mdi/js'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import store from '@/store'
import router from '@/router'
// eslint-disable-next-line import/extensions
import SearchPanel from '@/components/search/SearchPanel'
import SearchStopPoints from '@/components/search/SearchStopPoints'
import SearchMap from '@/components/search/SearchMap'
import SearchPayment from '@/components/search/SearchPayment'
import CustomToolbar from "@core/components/toolbar/CustomToolbar";
import {watch} from "@vue/composition-api/dist/vue-composition-api";
import Road from '@/views/road/Road.vue'

export default {
  name: 'SearchDetails',
  components: {
    Road,
    CustomToolbar,
    SearchPayment,
    SearchMap,
    SearchStopPoints,
    SearchPanel,
    LoaderDialog,
  },
  computed: {
    ...mapState('search', ['search']),
  },

  setup() {
    const isDialogVisible = ref(false)
    const userTab = ref(null)
    // eslint-disable-next-line no-shadow,no-use-before-define
    const route = getCurrentInstance().proxy.$route

    const getUser = () => {
      const id = route.params.id !== undefined ? route.params.id : null
      if (id !== null) {
        store.state.destination.field.search_id = id
        if (store.state.search.search === null || store.state.search.search.id !== id) {
          isDialogVisible.value = true
          store.dispatch('search/getSearchById', id).then(() => {
            isDialogVisible.value = false
          }).catch(error => {
            console.log(error)
          })
        }
      }
    }

    const refreshObjet = () => {
      const id = route.params.id !== undefined ? route.params.id : null
      if (id !== null) {
        isDialogVisible.value = true
        store.dispatch('search/getSearchById', store.state.search.search.id).then(() => {
          isDialogVisible.value = false
        }).catch(error => {
          console.log(error)
          router.back()
        })
      }
    }

    onMounted(getUser)

    const prev = () => {
      router.back()
    }

    const tabs = [
      { icon: mdiMapMarkerDistance, title: 'Arrêts' },
      { icon: mdiMapMarkerDistance, title: 'Trajets' },
    ]

    watch(() => store.state.search.refreshSearchCount, () => {
      isDialogVisible.value = true
      store.dispatch('search/getSearchById', store.state.search.refreshSearchId).then(() => {
        isDialogVisible.value = false
      }).catch(error => {
        console.log(error)
      })
    }, { deep: true })

    return {
      isDialogVisible,
      getUser,
      prev,
      refreshObjet,
      tabs,
      userTab,
      icons: {
        mdiRefresh,
        mdiArrowLeftBoldCircleOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
