<template>
  <v-card>
    <div class="d-flex align-center justify-space-between">
      <div>
        <v-card-title class="text-no-wrap">
          Trajet recherchée
        </v-card-title>
        <v-card-subtitle>Détails sur le trajet</v-card-subtitle>
      </div>

      <v-avatar
        color="primary"
        size="48"
        class="v-avatar-light-bg primary--text me-5"
      >
        <v-icon
          size="30"
          color="primary"
        >
          {{ icons.mdiSearch }}
        </v-icon>
      </v-avatar>
    </div>

    <v-card-text>
      <v-list>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiBusArticulatedFront }}
            </v-icon>
            Départ:
          </span>
          <span class="text--secondary font-weight-bold">{{ search.departure_name }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiBusArticulatedEnd }}
            </v-icon>
            Arrivé:
          </span>
          <span class="text--secondary font-weight-bold">{{ search.destination_name }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiSeatPassenger }}
            </v-icon>
            Places:
          </span>
          <span class="text--secondary font-weight-bold">
            <v-chip
              small
            >
              {{ search.nb_places }}
            </v-chip>
          </span>
        </v-list-item>

        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiClockStart }}
            </v-icon>
            Date et heure de départ:
          </span>
          <span class="text--secondary font-weight-bold">{{ search.start_date ? formattingStartDate(search.start_date) : null }}</span>
        </v-list-item>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">
            <v-icon
              size="15"
            >
              {{ icons.mdiCalendar }}
            </v-icon>
            Date de création:
          </span>
          <span class="text--secondary font-weight-bold">{{ search.created_at ? formattingDate(search.created_at) : null }}</span>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiSearch, mdiImageText, mdiCash, mdiClockStart, mdiBusArticulatedFront, mdiBusArticulatedEnd, mdiBadgeAccountAlert,
  mdiSeatPassenger, mdiCashMarker, mdiCalendar, mdiCalendarMinus, mdiCalendarEdit, mdiMapMarkerUp, mdiMapMarkerDown,
} from '@mdi/js'
import { formatDate, formatStartDate } from '@core/date/dateFunctions'
import {intToCurrency} from "@core/dynamicComponents/dynamicComponents";
import store from "@/store";

export default {
  name: 'SearchCard',
  props: {
    search: {
      type: Object,
      required: true,
    },
  },

  methods: {
    formattingDate(date) {
      return formatDate(date)
    },

    formattingStartDate(date) {
      return formatStartDate(date)
    },
  },

  setup() {
    const formatAmount = amount => intToCurrency(store.state.user.lang, {
      style: 'currency',
      currency: 'XAF',
      minimumFractionDigits: 0,
    }, amount)

    return {
      formatAmount,
      icons: {
        mdiSearch,
        mdiImageText,
        mdiCash,
        mdiClockStart,
        mdiBusArticulatedFront,
        mdiBusArticulatedEnd,
        mdiSeatPassenger,
        mdiCashMarker,
        mdiCalendar,
        mdiCalendarMinus,
        mdiCalendarEdit,
        mdiMapMarkerDown,
        mdiMapMarkerUp,
        mdiBadgeAccountAlert,
      },
    }
  },
}
</script>

<style scoped>

</style>
