<template>
  <div>
    <v-skeleton-loader
      v-show="isDialogVisible"
      boilerplate
      class="mx-auto mt-2"
      elevation="2"
      max-width="840"
      type="card-avatar, article, actions"
    ></v-skeleton-loader>
    <v-card-text v-show="!isDialogVisible">
      <l-map
        ref="lmapref"
        :key="key"
        style="height: 475px"
        :zoom="zoom"
        :center="[item.city.altitude, item.city.longitude]"
      >
        <l-tile-layer
          :url="url"
          :attribution="attribution"
        />
        <l-marker :lat-lng="[item.city.altitude, item.city.longitude, { draggable: 'true' }]">
          <l-popup><strong>DEPART:</strong> {{ item.city.name }}</l-popup>
        </l-marker>
        <l-marker :lat-lng="[item.destination.altitude, item.destination.longitude, { draggable: 'true' }]">
          <l-popup><strong>ARRIVEE:</strong> {{ item.destination.name }}</l-popup>
        </l-marker>
        <l-marker
          :key="index"
          v-for="(destination, index) in item.destinations"
          :icon="iconData"
          :lat-lng="[destination.city.altitude, destination.city.longitude, { draggable: 'true' }]"
        >
          <l-popup>{{ destination.city.name }}</l-popup>
        </l-marker>
        <l-polyline
          :lat-lngs="$store.state.search.coordinates"
          :color="polylineSearch.color"
        />
      </l-map>
    </v-card-text>
  </div>
</template>

<script>
import {
  LMap, LTileLayer, LMarker, LPopup, LPolyline,
} from 'vue2-leaflet'
import { Icon, icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { mapState } from 'vuex'
import {
  onMounted,
  computed,
  ref,
  watch,
  onBeforeUpdate,
  onUpdated,
  getCurrentInstance,
  onBeforeMount,
} from '@vue/composition-api'

import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import {
  leafletBasic,
  leafletMarkerCirclePolygon,
  leafletPopup,
  leafletGeoJSON,
  leafletLayerGroup,
  leafletCustomIcon,
} from './code'
import store from '@/store'

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  name: 'SearchMap',

  components: {
    LoaderDialog,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LPolyline,
  },

  model: {
    prop: 'search',
    event: 'update:is-form-active',
  },

  props: {
    search: {
      type: Object,
      default: null,
    },
  },

  setup(props, { emit }) {
    const item = computed({
      get: () => props.search,
      set: value => emit('update:search', value),
    })

    const lmapref = ref(null)

    const center = ref([])

    const isDialogVisible = ref(false)

    const key = ref(0)

    const iconData = icon({
      iconUrl: require('@/assets/images/png/map_marker_icon.png'),
      iconSize: [38, 40],
      iconAnchor: [18, 17],
    })

    const polylineSearch = ref({
      latlngs: [],
      color: 'green',
    })
    const instance = getCurrentInstance()
    const getOsmRoute = searchId => {
        setTimeout(() => {
        isDialogVisible.value = true
        store.dispatch('search/getOsmRouteById', searchId).then(() => {
          polylineSearch.value.latlngs.push(store.state.search.coordinates)
          center.value.push(item.value.city.altitude)
          center.value.push(item.value.city.longitude)
          isDialogVisible.value = false
        }).catch(error => {
          console.log(error)
        })
      }, 500)
    }

    const getGmapKey = () => {
      if (store.state.search.gmapKey === null) {
        store.dispatch('search/getGoogleMapKey').catch(error => {
          console.log(error)
        })
      }
    }

    watch(() => store.state.search.search.id, (newValue, oldValue) => {
      getOsmRoute(newValue)
    })

    onBeforeUpdate(() => {
      key.value += 1
    })

    return {
      lmapref,
      key,
      getOsmRoute,
      center,
      item,
      iconData,
      isDialogVisible,
      polylineSearch,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 7,
      codeSnippets: {
        leafletBasic,
        leafletMarkerCirclePolygon,
        leafletPopup,
        leafletGeoJSON,
        leafletLayerGroup,
        leafletCustomIcon,
      },
    }
  },
}
</script>

<style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>
