<template>
  <div>
    <v-skeleton-loader
      v-show="isDialogVisible"
      boilerplate
      class="mx-auto mt-2"
      elevation="2"
      max-width="840"
      type="article"
    ></v-skeleton-loader>
    <v-timeline
      v-show="!isDialogVisible"
      direction="horizontal"
      line-inset="12"
    >
      <div
        v-for="(destination, index) in destinations"
        :key="index"
      >
        <v-timeline-item color="none">
          <template v-slot:opposite>
            {{ destination.city.name }}
          </template>
          <template v-slot:icon>
            <v-icon color="primary">
              {{ icons.mdiMapMarker }}
            </v-icon>
          </template>
          <div>
            <v-chip
              class="ma-1 "
              color="primary"
              label
            >
              <v-icon
                start
                icon="mdi-cash"
              ></v-icon>
              {{ destination.global_amount }} FCFA
            </v-chip>
            <v-chip
              class="ma-1"
              color="secondary"
              label
            >
              <v-icon
                start
                icon="mdi-label"
              ></v-icon>
              {{ destination.price }} FCFA
            </v-chip>
          </div>
        </v-timeline-item>
      </div>
    </v-timeline>
  </div>
</template>

<script>
import CarDetailsDialog from '@core/components/user/CarDetailsDialog'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import ConfirmDialog from '@core/components/dialogs/ConfirmDialog'
import { mapGetters, mapState } from 'vuex'
import {
  mdiAccountLock,
  mdiDeleteOutline,
  mdiDotsVertical, mdiEyeOutline, mdiLockOpen,
  mdiPencilOutline, mdiPlus,
  mdiSquareEditOutline,
  mdiMapMarker,
  mdiCashMarker,
  mdiCashMultiple

} from '@mdi/js'
import { formatDate } from '@core/date/dateFunctions'
import store from '@/store'

export default {
  name: 'SearchStopPoints',

  // eslint-disable-next-line vue/no-unused-components
  components: { CarDetailsDialog, LoaderDialog, ConfirmDialog },

  data() {
    return {
      currentPage: null,
      lastPage: null,
      dialogDelete: false,
      isDialogVisible: false,
      isDialogVisible2: false,
      dialog: false,
    }
  },

  computed: {
    ...mapGetters('destination', ['destinations', 'current_page', 'last_page', 'per_page']),
    ...mapState('destination', ['field']),
  },

  created() {
    this.getElts(this.current_page ? this.current_page : null)
  },

  methods: {
    formattingDate(date) {
      return formatDate(date)
    },

    // eslint-disable-next-line no-unused-vars
    openItem(item) {
      this.isDialogVisible2 = true
      store.dispatch('destination/getDestinationById', item.id).then(() => {
        this.isDialogVisible2 = false
      })
      this.dialog = true
    },

    closeDialog() {
      this.dialog = false
    },

    getElts(page = 1) {
      setTimeout(() => {
        this.isDialogVisible = true
        store.dispatch('destination/getDestinationsList', { page, field: this.field }).then(() => {
          this.currentPage = this.current_page
          this.lastPage = this.last_page
          this.isDialogVisible = false
        })
      }, 500)
    },

    // eslint-disable-next-line camelcase
    getEltsPerPage(per_page) {
      // eslint-disable-next-line camelcase
      if (per_page !== null) {
        this.isDialogVisible = true
        // eslint-disable-next-line camelcase
        this.field.paginate = per_page
        this.itemsPerPage = parseInt(per_page, 10)
        store.dispatch('destination/getDestinationsList', {
          page: this.currentPage,
          field: this.field,
        }).then(() => {
          this.currentPage = this.current_page
          this.lastPage = this.last_page
          this.isDialogVisible = false
        })
      }
    },

    getEltsByFields() {
      this.isDialogVisible = true
      store.dispatch('destination/getDestinationsList', {
        page: this.currentPage,
        field: this.field,
      }).then(() => {
        this.currentPage = this.current_page
        this.lastPage = this.last_page
        this.isDialogVisible = false
      })
    },

    loading() {
      this.isDialogVisible = !this.isDialogVisible
    },
  },

  setup() {
    return {
      headers: [
        { text: 'VILLE', value: 'city.name' },
        { text: 'PRIX (FCFA)', value: 'global_amount' },
        { text: 'PRIX HORS FRAIS (FCFA)', value: 'price' },
        { text: 'DATE DE CREATION', value: 'created_at' },
        { text: 'DATE DE MISE A JOUR', value: 'updated_at' },
      ],
      itemsPerPage: parseInt(store.state.destination.field.paginate, 10),

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiPlus,
        mdiEyeOutline,
        mdiMapMarker
      },
    }
  },
}
</script>

<style scoped>

</style>
